var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "parent-container" }, [
    _vm.hasSysAdminPermission || _vm.currentCompany
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.dataLoading,
                expression: "dataLoading",
              },
            ],
            staticClass: "manager-container",
          },
          [
            _vm.hasSysAdminPermission
              ? _c("div", { staticClass: "left" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c("base-block-title", {
                        attrs: {
                          title: _vm.$t("company"),
                          "sub-title": _vm.filteredCompanies.length,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "search-bar" },
                    [
                      _c("el-input", {
                        staticClass: "round-input",
                        attrs: { placeholder: "Search Company Name" },
                        model: {
                          value: _vm.searchCompanyInput,
                          callback: function ($$v) {
                            _vm.searchCompanyInput = $$v
                          },
                          expression: "searchCompanyInput",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "content" },
                    _vm._l(_vm.filteredCompanies, function (item) {
                      return _c("company-item", {
                        key: item.id,
                        attrs: {
                          company: item,
                          active:
                            _vm.selectedCompany &&
                            item.id === _vm.selectedCompany.id,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleAdminSelectCompany(item)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c("div", { staticClass: "div-main-title" }, [
                  _c("h1", [_vm._v("Cargo Officers Management")]),
                  _c(
                    "div",
                    { staticClass: "div-main-action" },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "round-input margin-right-10",
                          staticStyle: { width: "300px" },
                          attrs: { placeholder: "Search By Name or Email" },
                          nativeOn: {
                            keypress: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.getCargoOfficers(
                                _vm.selectedCompany.id
                              )
                            },
                          },
                          model: {
                            value: _vm.cargoOfficerSearchValue,
                            callback: function ($$v) {
                              _vm.cargoOfficerSearchValue = $$v
                            },
                            expression: "cargoOfficerSearchValue",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getCargoOfficers(
                                  _vm.selectedCompany.id
                                )
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                      _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
                        ? _c(
                            "el-button",
                            {
                              attrs: { round: "", type: "primary" },
                              on: { click: _vm.handleOpenCargoOfficerDialog },
                            },
                            [_vm._v("Add Cargo Officer")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.cargoOfficers,
                      border: "",
                      "header-cell-style": _vm.HEADER_CELL_STYLE,
                      "cell-style": _vm.CELL_STYLE,
                      width: "100%",
                    },
                  },
                  [
                    _c("el-table-column", { attrs: { type: "index" } }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Name",
                        "min-width": "180px",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [_vm._v(_vm._s(scope.row.name))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1281056470
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Email",
                        "min-width": "280px",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [_vm._v(_vm._s(scope.row.email))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3354720637
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Code",
                        "min-width": "60px",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.code
                                        ? scope.row.code.replace(/./g, "*")
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        792865368
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Active",
                        "min-width": "60px",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    disabled: !scope.row.isOwner,
                                    "active-color": "#13ce66",
                                    "inactive-color": "#ff4949",
                                  },
                                  on: {
                                    change: (value) =>
                                      value
                                        ? _vm.activateCargoOfficerConfirmation(
                                            scope.row
                                          )
                                        : _vm.deactivateCargoOfficerConfirmation(
                                            scope.row
                                          ),
                                  },
                                  model: {
                                    value: scope.row.isActive,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "isActive", $$v)
                                    },
                                    expression: "scope.row.isActive",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1882958579
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "min-width": "240px",
                        label: "Transferred To/From",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.charterId
                                  ? _c(
                                      "div",
                                      { staticClass: "transfer-to-from" },
                                      [
                                        scope.row.isOwner
                                          ? _c(
                                              "span",
                                              { staticClass: "arrow-icon" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("@/assets/images/action_icons/transfer_co_to.png"),
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "arrow-icon" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("@/assets/images/action_icons/transfer_co_from.png"),
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            ),
                                        _c(
                                          "p",
                                          {
                                            style: {
                                              color: scope.row.isOwner
                                                ? "#222BFF"
                                                : "#FC7C05",
                                              fontWeight: "400",
                                              display: "block",
                                              fontSize: "14px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.transferToOrFrom
                                                  ? scope.row.transferToOrFrom
                                                  : ""
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1584110656
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "50px", "show-overflow-tooltip": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Edit",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-edit",
                                        circle: "",
                                        disabled: !scope.row.isOwner,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editCargoOfficer(scope.row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        315464988
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "50px", "show-overflow-tooltip": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Resend Email",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    scope.row.email
                                      ? _c("el-button", {
                                          attrs: {
                                            type: "info",
                                            icon: "el-icon-message",
                                            circle: "",
                                            disabled: !scope.row.isOwner,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sendEmailCargoOfficerConfirmation(
                                                scope.row
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4101181442
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "50px", "show-overflow-tooltip": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Reset Code",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    scope.row.email
                                      ? _c("el-button", {
                                          attrs: {
                                            type: "danger",
                                            icon: "el-icon-refresh",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.resetCargoOfficerCodeConfirmation(
                                                scope.row
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1112987882
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "50px", "show-overflow-tooltip": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "flex-between-row" },
                                  [
                                    scope.row.charterId == null
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: "Transfer Cargo Officer",
                                              placement: "top-end",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "transfer-to",
                                                attrs: {
                                                  size: "small",
                                                  circle: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        {
                                                          _vm.showTransferCargoOfficerDialog(
                                                            scope.row
                                                          )
                                                        }
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        height: "12px",
                                                        width: "12px",
                                                      },
                                                      attrs: {
                                                        src: require("@/assets/images/action_icons/transfer_action_icon.png"),
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: "Stop Current Transfer",
                                              placement: "top-end",
                                            },
                                          },
                                          [
                                            _c("el-button", {
                                              staticClass: "transfer-stop",
                                              attrs: {
                                                size: "small",
                                                icon: "el-icon-finished",
                                                circle: "",
                                                disabled:
                                                  scope.row.charterId ==
                                                  _vm.selectedCompany.id,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  {
                                                    _vm.stopTransfer(scope.row)
                                                  }
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        487187561
                      ),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "div-main-list-page-table-pagination" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "page-size": _vm.cargoOfficerPagination.pageSize,
                        "page-sizes": [5, 10, 20, 50, 100],
                        "current-page": _vm.cargoOfficerPagination.pageNumber,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.cargoOfficerPagination.total,
                      },
                      on: {
                        "size-change": _vm.handleCargoOfficerPageSizeChange,
                        "current-change":
                          _vm.handleCargoOfficerPageNumberChange,
                        "update:pageSize": function ($event) {
                          return _vm.$set(
                            _vm.cargoOfficerPagination,
                            "pageSize",
                            $event
                          )
                        },
                        "update:page-size": function ($event) {
                          return _vm.$set(
                            _vm.cargoOfficerPagination,
                            "pageSize",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.saveCargoOfficerLoading,
                    expression: "saveCargoOfficerLoading",
                  },
                ],
                staticClass: "el-dialog-save-cargo-officer",
                attrs: {
                  title: "Save Cargo Officer",
                  visible: _vm.dialogSaveCargoOfficer,
                  width: "35%",
                  "before-close": _vm.handleCloseCargoOfficerDialog,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogSaveCargoOfficer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "cargoOfficerForm",
                    attrs: {
                      model: _vm.cargoOfficerForm,
                      rules: _vm.rules,
                      "label-position": "top",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Name", prop: "name" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.cargoOfficerForm.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cargoOfficerForm, "name", $$v)
                                },
                                expression: "cargoOfficerForm.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Email", prop: "email" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.cargoOfficerForm.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cargoOfficerForm, "email", $$v)
                                },
                                expression: "cargoOfficerForm.email",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { attrs: { slot: "footer" }, slot: "footer" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { round: "", type: "primary" },
                        on: { click: _vm.saveCargoOfficer },
                      },
                      [_vm._v(" Confirm ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { outline: "", round: "" },
                        on: { click: _vm.handleCloseCargoOfficerDialog },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  visible: _vm.transferCargoOfficerDialogVisible,
                  "close-on-click-modal": false,
                  width: "40%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.transferCargoOfficerDialogVisible = $event
                  },
                },
              },
              [
                _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "400",
                        "margin-right": "10px",
                        "font-size": "12",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("name")) + ":")]
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.selectedTransferCargoOfficer &&
                          _vm.selectedTransferCargoOfficer.name
                          ? _vm.selectedTransferCargoOfficer.name
                          : "-"
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "400",
                        "margin-right": "10px",
                        "font-size": "12",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("email")) + ":")]
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.selectedTransferCargoOfficer &&
                          _vm.selectedTransferCargoOfficer.email
                          ? _vm.selectedTransferCargoOfficer.email
                          : "-"
                      )
                    ),
                  ]),
                ]),
                _c(
                  "el-form",
                  { ref: "transferShipForm" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "500px",
                          height: "20px",
                          display: "flex",
                          "flex-direction": "row",
                          "justify-content": "start",
                          "font-size": "14.5",
                          "font-weight": "500",
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("* "),
                        ]),
                        _vm._v(" " + _vm._s(_vm.$t("transferTo"))),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "transferTocargoOfficerorId",
                          rules: { required: true, trigger: "blur" },
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              placeholder: "Please enter a keyword",
                              "remote-method": _vm.getTransferToCompanyOpts,
                              loading: _vm.selectOpstSearchLoading,
                            },
                            model: {
                              value: _vm.transferFormData.organizationId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.transferFormData,
                                  "organizationId",
                                  $$v
                                )
                              },
                              expression: "transferFormData.organizationId",
                            },
                          },
                          _vm._l(_vm.transferToCompanyOpst, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { round: "" },
                        on: {
                          click: function ($event) {
                            _vm.transferCargoOfficerDialogVisible = false
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", round: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleTransferOrTerminateCargoOfficer(
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("startTransfer")) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }